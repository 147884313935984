.Login_wrapper {
  background-color: linear-gradient(black,red);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .Login_inner {
    width: 360px;
    margin: auto;
    background-color: white;
    box-shadow: 2px 2px 12px grey;
    border-radius: 12px;
    padding: 48px 60px;
    margin-top: 120px;
  }
  .Login_logo{
    width: 120px;
    border-radius: 12px;
    margin-bottom: 12px;
  }
}